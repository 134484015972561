import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { axiosInstance } from "../../../interceptors/AxiosInterceptor";
import AddAssociatesDialog from "./AddAssociateDialog";

const ProjectResources = (props) => {
  const { formData, setFormData, onError, onSuccess, user } = props;
  const [selectedUsers, setSelectedUsers] = useState(formData?.users);
  const [isAddAssociatesDialogOpen, setIsAddAssociatesDialogOpen] = useState(false);
  const [selectedItemForDeletion, setSelectedItemForDeletion] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [associates, setAssociates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingStates, setLoadingStates] = useState({});
  const displayedAssociates = formData?.users;
  const role = user.roles[0]

  useEffect(() => {
    setLoading(true);
    axiosInstance
      .get(`/user/associatesLists?sortBy=ACTIVE&tenantId=${formData?.tenant?.id}`)
      .then((res) => {
        setAssociates(res?.data);
        setLoading(false);
      }).catch(err => {
        setLoading(false);
        console.log(err);
      });
  }, [formData]);

  const handleAddAssociatesDialogOpen = () => {
    setIsAddAssociatesDialogOpen(true);
  };

  const handleAddAssociates = (associates) => {
    setIsAddAssociatesDialogOpen(false);
    setLoadingStates((prev) => ({ ...prev, addAssociates: true }));
    // let filteredAssociates = [];
    console.log(associates);

    const allAssociates = new Set(associates);
    let filteredAssociates = []
    for (const data of allAssociates) {

      if (!displayedAssociates.find(exitedOne => exitedOne.associateId === data.associateId)) {
        delete data["projects"]
        delete data["tenant"]
        delete data["status"]
        filteredAssociates.push(data)
      }
    }
    // associates.map((data) => {
    //   // const userProjects = data?.projects !== null && data?.projects.split(",").map((data) => { return data.trim() })
    //   // const add = userProjects.some((data) => data === formData.title);
    //   // if (!add) {
    //   delete data["projects"]
    //   delete data["tenant"]
    //   delete data["status"]
    //   filteredAssociates.push(data);
    //   // }
    // });
    console.log(filteredAssociates)

    if (filteredAssociates.length === 0) {
      setLoadingStates((prev) => ({ ...prev, addAssociates: false }));
      onError({
        response: {
          data: "Associates are Already existed or you Haven't any associate",
        },
      });
    } else {
      axiosInstance
        .post(`user/assign?id=${formData?.id}`, filteredAssociates)
        .then((response) => {
          const updatedAssociates = [...formData?.users, ...filteredAssociates];
          setFormData((prevFormData) => ({
            ...prevFormData,
            users: updatedAssociates,
          }));
          setIsAddAssociatesDialogOpen(false);
          onSuccess("Associates assigned successfully");
        })
        .catch((error) => {
          console.error("Error adding associate:", error);
        })
        .finally(() => {
          setLoadingStates((prev) => ({ ...prev, addAssociates: false }));
          setIsAddAssociatesDialogOpen(false);
        });
    }
  };

  const handleDeleteClick = (associate) => {
    setSelectedItemForDeletion(associate);
    setIsDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = () => {
    const associateIdToDelete = selectedItemForDeletion;
    setIsDeleteDialogOpen(false);
    setLoadingStates((prevLoadingStates) => ({
      ...prevLoadingStates,
      [selectedItemForDeletion]: true,
    }));
    axiosInstance
      .delete(
        `user/remove/associate?associateId=${associateIdToDelete}&projectId=${formData?.id}`
      )
      .then(() => {
        const updatedAssociates = formData?.users.filter(
          (associate) => associate?.associateId !== associateIdToDelete
        );
        setSelectedUsers(updatedAssociates);
        setFormData((prevFormData) => ({
          ...prevFormData,
          users: updatedAssociates,
        }));
        setIsDeleteDialogOpen(false);
        onSuccess("Associate deleted successfully");
      })
      .catch((error) => {
        onError(error);
        console.error("Error deleting associate:", error);
      })
      .finally(() => {
        setLoadingStates((prevLoadingStates) => ({
          ...prevLoadingStates,
          [selectedItemForDeletion]: false,
        }));
        setIsDeleteDialogOpen(false);
      });
  };

  const handleDeleteCancel = () => {
    setIsDeleteDialogOpen(false);
  };

  return (
    <Card>
      <CardContent>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={12} container justifyContent="flex-end">
            {loadingStates?.["addAssociates"] ?
              (
                <CircularProgress />
              ) :
              (
                <Button
                  size="small"
                  variant="contained"
                  sx={{ backgroundColor: "#008080", fontFamily: "Lucida Grande" }}
                  onClick={handleAddAssociatesDialogOpen}
                  disabled={formData.projectManager === null}
                >
                  Assign Associates
                </Button>
              )}
          </Grid>
        </Grid>
        <Paper
          elevation={0}
          style={{
            backgroundColor: "#FFFFFF",
            padding: "5px",
          }}
        >
          {loading ? (
            <Grid sx={{ textAlignLast: "center" }} item xs={12}>
              <Box style={{ paddingTop: "50px", paddingBottom: "40px" }}>
                <CircularProgress />
              </Box>
            </Grid>
          ) : displayedAssociates && displayedAssociates.length > 0 ? (
            <TableContainer sx={{ maxHeight: 300 }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={tableCss.header}
                    >
                      ASSOCIATE ID
                    </TableCell>
                    <TableCell
                      style={tableCss.header}
                    >
                      ASSOCIATE NAME
                    </TableCell>
                    <TableCell
                      style={tableCss.header}
                    >
                      DESIGNATION
                    </TableCell>
                    {role === "Admin" ? <TableCell
                      style={tableCss.header}
                    >
                      ACTION
                    </TableCell> : <></>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {displayedAssociates.map((associate) => (
                    <TableRow key={associate.associateId}>
                      <TableCell style={tableCss.body}>{associate.associateId}</TableCell>
                      <TableCell style={tableCss.body}>
                        {associate?.role === "Project Manager" ? (
                          <Stack direction={"row"} spacing={1} display={'flex'} alignItems={"center"}
                            sx={{ color: associate?.role === "Project Manager" && formData?.projectManager?.username === associate.username ? 'green' : '' }}>
                            <div>{associate?.firstname + " " + associate?.lastname}</div>
                            <div>
                              (Manager)
                            </div>
                          </Stack>
                        ) :
                          (associate?.firstname + " " + associate?.lastname)}
                      </TableCell>
                      <TableCell style={tableCss.body}>{associate?.designation}</TableCell>
                      {role === "Admin" ? <TableCell>
                        {loadingStates[associate.associateId] ? (
                          <CircularProgress />
                        ) : (
                          <Tooltip title="Remove">
                            <DeleteIcon
                              sx={{ cursor: "pointer" }}
                              onClick={() => {
                                if (formData?.projectManager?.associateId === associate.associateId) {
                                  onError({ response: { data: `You can't remove the default Project Manager until the Responsiblity is assigned to another Associate` } })
                                }
                                else {
                                  handleDeleteClick(associate.associateId)
                                }
                              }
                              }
                            />
                          </Tooltip>
                        )}
                      </TableCell> : <></>}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography sx={{ textAlign: "center", fontFamily: "Luicda Grande" }}>
              No associates assigned to {formData.title}
            </Typography>
          )}

          <AddAssociatesDialog
            open={isAddAssociatesDialogOpen}
            onClose={() => setIsAddAssociatesDialogOpen(false)}
            handleAddAssociates={handleAddAssociates}
            associates={associates}
            displayedAssociates={displayedAssociates}
            formData={formData}
            selectedUsers={selectedUsers}
            setSelectedUsers={setSelectedUsers}
          />

          <Dialog open={isDeleteDialogOpen} onClose={handleDeleteCancel}>
            <DialogTitle>Confirm Deletion</DialogTitle>
            <DialogContent>
              <DialogContentText sx={{ fontFamily: "Luicda Grande" }}>
                Are you sure you want to remove this associate from the project?
                This action cannot be undone!
                {formData?.projectManager?.associateId === selectedItemForDeletion ?
                  <>He is the Default Project Manager for this project. Do you still want to continue</> : <></>
                }
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                size="small"
                variant="contained"
                onClick={handleDeleteCancel}
                color="primary"
                sx={{ fontFamily: "Luicda Grande" }}
              >
                Cancel
              </Button>
              <Button
                size="small"
                variant="contained"
                onClick={handleDeleteConfirm}
                color="primary"
                sx={{ fontFamily: "Luicda Grande" }}
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        </Paper>
      </CardContent>
    </Card >
  );
};
const tableCss = {
  header: {
    padding: '10px',
    backgroundColor: "#d5ecec",
    fontWeight: "bolder",
    fontFamily: "Lucida Grande",
    fontFamily: "Lucida Grande",
  },
  body: {
    padding: '10px',
    fontFamily: "Lucida Grande"
  }
}
export default ProjectResources;
