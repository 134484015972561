import { Alert, Box, Button, Card, CardActions, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Menu, MenuItem, Rating, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { axiosInstance } from '../../../interceptors/AxiosInterceptor';
import moment from 'moment';
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import jsPDF from 'jspdf';
import DownloadIcon from "@mui/icons-material/Download";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const UserAppraisal = (props) => {
    const { user } = props;
    const role = user.roles[0]
    const [ratings, setRatings] = useState([]);
    const [appraisaldata, setAppraisalData] = useState({ comments: '' });
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [isSubmittedForRange, setIsSubmittedForRange] = useState(false);
    const location = useLocation();
    const [menuanchorEl, setMenuAnchorEl] = useState(null);
    const [comments, setComments] = useState('');

    const [startDate, setStartDate] = useState(location.state ? location.state.startDate : moment(new Date()).format('DD') > 15 ? moment(new Date()).startOf('month').add(15, 'day').format('YYYY-MM-DD') : moment(new Date()).startOf('month').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(location.state ? location.state.endDate : moment(new Date()).format('DD') > 15 ? moment(new Date()).endOf('month').format('YYYY-MM-DD') : moment(new Date()).startOf('month').add(14, 'days').format('YYYY-MM-DD'));
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [message, setMessage] = useState('');
    const [submitloading, setSubmitLoading] = useState(false);


    useEffect(() => {
        if (location.state.type === "User") {
            // setAppraisalData(res.data)
            setComments(location.state.comments)
            setRatings(location.state.ratings)
        }
        else {
            axiosInstance.put("/user/getAssociateRatings", { startDate: startDate, endDate: endDate, user: { username: location.state.username }, project: props.projects.find((data) => data.title === location.state.project) }).then(res => {
                setAppraisalData(res.data)
                setComments(res.data.comments)
                if (Object.keys(res.data).length !== 0) {
                    setRatings(JSON.parse(res.data.ratings))
                    setIsSubmittedForRange(true);
                }
                else {
                    setIsSubmittedForRange(false);

                }
            }).catch(err => {
                console.log(err);

            })
        }
    }, [startDate, endDate, submitted])


    const UserAppraisal = [
        {
            serialNo: 1,
            Objective: 'Project',
            subObjective: [
                { desc: 'Project', matrixTarget: 'Delivered the assigned task/assignment on time.' },
                { matrixTarget: 'Delivery with proper documentation.' },
                { matrixTarget: 'Business Solution & improvements suggested' },
                { matrixTarget: 'Helping other team members/ projects beyond assigned tasks' },
                { desc: '', matrixTarget: 'Timely Communication and follow-up' },
                { desc: '', matrixTarget: 'Quality of Work' },
            ],
            rating: [
                { sno: 1, ratingValue: 1 },
                { sno: 2, ratingValue: 3 }
            ]
        },
        {
            serialNo: 2,
            Objective: 'Exceptional Contribution',
            subObjective: [
                { desc: 'Exceptional Contribution', matrixTarget: 'Exceptional work done in the project or for practice or for an organization that resulted improvement in Business process, and example reduction in cost, usage of new technology, time saving or generated a reusable component' },
            ],
            rating: [1, 2, 3, 4, 5, 6]
        },
        {
            serialNo: 3,
            Objective: 'Trainings & Talent Development',
            subObjective: [
                { desc: 'Trainings & Talent Development', matrixTarget: 'Learn atleast one new concept that is aligned to Quarterly learning goal' },
            ],
            rating: [
                { sno: 1, ratingValue: 4 }
            ]
        },
        {
            serialNo: 4,
            Objective: 'JIRA and Emportal',
            subObjective: [
                { desc: 'JIRA and Emportal', matrixTarget: 'Timely creation/updation of JIRA Stories and tasks' },
                { desc: '', matrixTarget: 'Timely submission of Weekly Emportal timesheets' },
                { desc: '', matrixTarget: 'Timely submission of leaves and remote clock-in in Keka' },
            ],
            rating: [1, 2, 3]
        },

    ]

    // let ratingObj = {}
    // const handleChange = (rowIndex, index, rating) => {
    //     ratingObj = { ...ratingObj, rowIndex: rowIndex, subIndex: index, rating: rating }

    //     setRatings((arr) => {
    //         const isAlreadyExisted = arr.find((rObj) => rObj.rowIndex === ratingObj.rowIndex && rObj.subIndex === ratingObj.subIndex)
    //         if (isAlreadyExisted) {
    //             arr.pop(isAlreadyExisted);
    //             arr.push(ratingObj);
    //         }
    //         else {
    //             arr.push(ratingObj);
    //         }
    //         return arr;
    //     })

    console.log(location.state);

    // }

    const handleDownloadPDF = () => {
        const pdfWidth = 210;
        const pdfHeight = 250;
        const pdf = new jsPDF({
            orientation: "landscape",
            unit: "mm",
            format: [pdfWidth, pdfHeight],
        });

        const logoUrl = 'https://tse3.mm.bing.net/th?id=OIP.xrnfPsu-nIEVf0HzuyWt3AAAAA&pid=Api&P=0&h=180';
        const centerX = pdf.internal.pageSize.width / 2;
        const imageWidth = 20;
        const imageHeight = 20;
        const imageY = 2;
        pdf.addImage(logoUrl, centerX - imageWidth / 2, imageY, imageWidth, imageHeight);

        // Preprocess data to split multiline cells into multiple rows
        const splitData = UserAppraisal.flatMap((data, rowIndex) => {
            const descriptions = data.subObjective.map(sub => sub.desc || '').join('\n').split('\n');
            const matrixTargets = data.subObjective.map(sub => sub.matrixTarget || '').join('\n').split('\n');
            const ratingsDemo = data.subObjective.map((sub, subIndex) => (ratings.find(r => r.rowIndex === rowIndex && r.subIndex === subIndex)?.rating || 0)).join('\n').split('\n');

            const maxRows = Math.max(descriptions.length, matrixTargets.length, ratingsDemo.length);
            return Array.from({ length: maxRows }, (_, i) => ({
                "s.no": data.serialNo,
                // "Objective": data.Objective,
                "Description": descriptions[i] || '',
                "Matrix & Target": matrixTargets[i] || '',
                "Rating (1-5)Low to High": ratingsDemo[i] || '',
            }));
        });

        const header = [
            's.no',
            // "Objective",
            "Description",
            "Matrix & Target",
            "Rating (1-5)Low to High",
        ];

        const userData = splitData.map(val => header.map(header => val[header]));

        const tableStartY = imageY + imageHeight + 20; //44

        pdf.autoTable({
            head: [header],
            body: userData,
            margin: { left: 8, right: 8, bottom: 10 },
            startY: tableStartY + 30,
            rowPageBreak: 'avoid',
            headStyles: { fillColor: "#008080", textColor: "#ffffff", fontStyle: "bold" },

            didDrawPage: function (data) {
                if (data.pageNumber === 1) {
                    pdf.setFont("Times New Roman, Times, serif");
                    pdf.text(`Dear Project Manager, Following is the Appraisal report of ${location.state.fullname}.`, 12, tableStartY - 6);
                    pdf.setFontSize(13);
                    pdf.text(`For the time period of ${moment(startDate).format('DD-MMM-yyyy')} to ${moment(endDate).format('DD-MMM-yyyy')} `, 12, 46);
                    pdf.text(`Rating Avg- ${(totalRatingSum / ratings.length).toFixed(2)}, Project- ${location.state.project}`, 12, startDate && endDate ? 54 : 46);
                    // const commentText = `Feedback: ${appraisaldata?.comments ?? 'No comments available'}`;
                    const commentText = `Feedback: ${comments || 'No comments available'}`;
                    const splitCommentText = pdf.splitTextToSize(commentText, 228);
                    let commentsYPosition = startDate && endDate ? 62 : 54;
                    pdf.text(splitCommentText, 12, commentsYPosition);
                }
            },
            didParseCell: function (data) {
                data.cell.styles.lineWidth = 0.1;
                data.cell.styles.lineColor = [0, 0, 0];
            },
        });
        pdf.save(`${location.state.fullname}.pdf`);
    };

    const handleDownloadExcel = () => {
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet([]);

        const splitData = UserAppraisal.flatMap((data, rowIndex) => {
            const descriptions = data.subObjective.map(sub => sub.desc || '').join('\n').split('\n');
            const matrixTargets = data.subObjective.map(sub => sub.matrixTarget || '').join('\n').split('\n');
            const ratingsDemo = data.subObjective.map((sub, subIndex) => (ratings.find(r => r.rowIndex === rowIndex && r.subIndex === subIndex)?.rating || 0)).join('\n').split('\n');

            const maxRows = Math.max(descriptions.length, matrixTargets.length, ratingsDemo.length);
            return Array.from({ length: maxRows }, (_, i) => ({
                "s.no": data.serialNo,
                // "Objective": data.Objective,
                "Description": descriptions[i] || '',
                "Matrix & Target": matrixTargets[i] || '',
                "Rating (1-5)Low to High": ratingsDemo[i] || '',
            }));
        });

        const header = [
            's.no',
            // "Objective",
            "Description",
            "Matrix & Target",
            "Rating (1-5)Low to High",
        ];

        XLSX.utils.sheet_add_aoa(ws, [header], { origin: "A1" });
        splitData.forEach((data, index) => {
            const rowData = header.map(header => data[header]);
            XLSX.utils.sheet_add_aoa(ws, [rowData], { origin: `A${index + 2}` });
        });

        const columnWidths = header.map((col) => ({ wch: col.length + 10 }));
        ws['!cols'] = columnWidths;
        XLSX.utils.book_append_sheet(wb, ws, 'Appraisal Report');

        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const excelBlob = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        saveAs(excelBlob, `${location.state.fullname}.xlsx`);
    };


    const handleChange = (rowIndex, subIndex, newValue) => {
        setRatings(prevRatings => {
            // Update existing ratings or add new one
            const updatedRatings = prevRatings.map(r =>
                r.rowIndex === rowIndex && r.subIndex === subIndex
                    ? { ...r, rating: newValue } // Update existing rating
                    : r
            );

            // If no rating was updated, add a new entry
            if (!updatedRatings.some(r => r.rowIndex === rowIndex && r.subIndex === subIndex)) {
                updatedRatings.push({ rowIndex, subIndex, rating: newValue });
            }

            return updatedRatings;
        });
    };

    const handleDownloadMenuClick = (event) => {
        setMenuAnchorEl(event.currentTarget);
    };

    let totalRatingSum = 0;
    ratings.map((r) =>
        totalRatingSum += r.rating
    )


    const handleStartDateChange = (e, type) => {
        setRatings([])
        setComments('')
        setIsSubmittedForRange(false);
        setSubmitted(false);
        if (type === "icon") {
            const newStartDate = moment(startDate).subtract(14, 'day').format('YYYY-MM-DD');
            const newEndDate = moment(endDate).subtract(14, 'day').format('YYYY-MM-DD');
            setStartDate(newStartDate);
            setEndDate(newEndDate);
        }
        else {
            const value = e.target.value;
            setStartDate(value);
        }
    };
    const handleEndDateChange = (e, type) => {
        setRatings([])
        setComments('')
        setIsSubmittedForRange(false);
        setSubmitted(false);
        if (type === "icon") {
            const newStartDate = moment(startDate).add(14, 'day').format('YYYY-MM-DD');
            const newEndDate = moment(endDate).add(14, 'day').format('YYYY-MM-DD');
            setStartDate(newStartDate);
            setEndDate(newEndDate);
        }
        else {
            const value = e.target.value;
            setEndDate(value);
        }
    };


    const renderDateFields = () => {
        return (
            <Box sx={{ display: "flex" }}>
                <Tooltip title="click to go to previous month">
                    {location.state.type === "User" ? <></> : <IconButton onClick={(e) => handleStartDateChange(e, "icon")}>
                        <ArrowBackIosIcon
                            fontSize="small"
                        />
                    </IconButton>}
                </Tooltip>
                <TextField
                    type="date"
                    value={startDate}
                    label="Start Date"
                    size="small"
                    error={
                        startDate &&
                            new Date(startDate) > new Date(endDate)
                            ? "Start date cannot be after end date"
                            : ""
                    }
                    helperText={
                        startDate &&
                            new Date(startDate) > new Date(endDate)
                            ? "Start date cannot be after end date"
                            : ""
                    }
                    InputLabelProps={{
                        style: { font: "small-caption", fontFamily: "Lucida Grande" },
                        shrink: true,
                    }}
                    InputProps={{
                        style: { font: "small-caption", fontFamily: "Lucida Grande" },
                    }}
                    disabled={location.state.type === "User"}
                    onChange={handleStartDateChange}
                />
                &nbsp; &nbsp;
                <TextField
                    type="date"
                    value={endDate}
                    label="End Date"
                    size="small"
                    error={
                        startDate &&
                            new Date(endDate) < new Date(startDate)
                            ? "End date cannot be before start date"
                            : ""
                    }
                    helperText={
                        startDate &&
                            new Date(endDate) < new Date(startDate)
                            ? "End date cannot be before start date"
                            : ""
                    }
                    InputLabelProps={{
                        style: { font: "small-caption", fontFamily: "Lucida Grande" },
                        shrink: true,
                    }}
                    InputProps={{
                        style: { font: "small-caption", fontFamily: "Lucida Grande" },
                    }}
                    disabled={location.state.type === "User"}
                    onChange={handleEndDateChange}
                />
                <Tooltip title="click to go to next month">
                    {location.state.type === "User" ? <></> : <IconButton onClick={(e) => handleEndDateChange(e, "icon")}>
                        <ArrowForwardIosIcon
                            fontSize="small"
                        />
                    </IconButton>}
                </Tooltip>
            </Box>
        );
    }


    const handleClick = () => {
        setIsDialogOpen(false);
        const data = {
            ratings: JSON.stringify(ratings),
            comments: comments,
            user: { username: location.state.username },
            project: props.projects.find((data) => data.title === location.state.project),
            startDate: startDate,
            endDate: endDate
        }
        setSubmitLoading(true)
        if (ratings.length !== 0) {
            axiosInstance.post("/user/submitRatings", data).then(res => {
                console.log(res.data);
                setSubmitted(true);

                setIsSubmittedForRange(true);
                setSeverity('success');
                setMessage("Ratings Submitted Successfully!");
                setSnackbarOpen(true);
                setSubmitLoading(false)
            }).catch(err => {
                console.log(err);
                setSubmitLoading(false)
            })
        }
        else {
            setSeverity('error');
            setMessage("Provide atleast 1 rating. Please try again.");
            setSnackbarOpen(true);
            setSubmitLoading(false)
        }


    }

    return (
        <Card>
            <Grid container xs={12} mt={2} pl={2}>
                <Grid item xs={3}>
                    <Typography variant='h6'>
                        Associate Performance
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    {renderDateFields()}
                </Grid>

                {<Grid item xs={3}>
                    {role === "User" ? <></> :
                        <IconButton
                            onClick={handleDownloadMenuClick}
                        // disabled={!isSubmittedForRange}  // Disable the button until submitted
                        // disabled={loading || Object.keys(errors).length !== 0}
                        >
                            <DownloadIcon />
                        </IconButton>}
                    <Menu
                        anchorEl={menuanchorEl}
                        open={Boolean(menuanchorEl)}
                        onClose={() => { setMenuAnchorEl(null); }}
                    >
                        <MenuItem
                            style={{ font: "small-caption" }}
                            onClick={() => handleDownloadPDF()}
                        >
                            Download PDF
                        </MenuItem>
                        <MenuItem
                            style={{ font: "small-caption" }}
                            onClick={() => handleDownloadExcel()}
                        >
                            Download Excel
                        </MenuItem>
                    </Menu>
                </Grid>}
            </Grid>
            <CardContent>
                <Grid container xs={12}>
                    <Grid item xs={6}>
                        <Typography variant='h6'>
                            Associate Name: {location.state.fullname}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant='h6'>
                            Project: {location.state.project}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>

                    </Grid>
                </Grid>

                <TableContainer id='content-to-print'>
                    <Table >
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ border: '1px solid', padding: '4px', backgroundColor: '#dde2eb' }}>
                                    <Typography sx={{ fontFamily: 'Lucida Grande, sans-serif', fontWeight: 'bold' }}>S.no</Typography>
                                </TableCell>
                                <TableCell sx={{ border: '1px solid', padding: '4px', backgroundColor: '#dde2eb' }}>
                                    <Typography sx={{ fontFamily: 'Lucida Grande, sans-serif', fontWeight: 'bold' }}>Description</Typography>
                                </TableCell>
                                {/* <TableCell sx={{ border: '1px solid', padding: '4px', backgroundColor: '#dde2eb' }}>
                                    <Typography sx={{ fontFamily: 'Lucida Grande, sans-serif', fontWeight: 'bold' }}>Description</Typography>
                                </TableCell> */}
                                <TableCell sx={{ border: '1px solid', padding: '4px', backgroundColor: '#dde2eb' }}>
                                    <Typography sx={{ fontFamily: 'Lucida Grande, sans-serif', fontWeight: 'bold' }}>Matrix & Target</Typography>
                                </TableCell>
                                {/* <TableCell sx={{ border: '1px solid', padding: '4px', backgroundColor: '#dde2eb' }}>
                                    <Typography sx={{ fontFamily: 'Lucida Grande, sans-serif', fontWeight: 'bold' }}>Time Period</Typography>
                                </TableCell> */}
                                <TableCell sx={{ border: '1px solid', padding: '4px', backgroundColor: '#dde2eb' }}>
                                    <Typography sx={{ fontFamily: 'Lucida Grande, sans-serif', fontWeight: 'bold' }}>Rating(1-5)Low to High</Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                UserAppraisal.map((data, rowIndex) => (
                                    <React.Fragment key={rowIndex}>
                                        {data.subObjective.map((sub, subIndex) => (
                                            <TableRow key={subIndex}>
                                                {subIndex === 0 && (
                                                    <>
                                                        <TableCell rowSpan={data.subObjective.length} sx={{ border: '1px solid', padding: '4px' }}>
                                                            <Typography variant="body2" sx={{ fontFamily: 'Lucida Grande, sans-serif' }}>
                                                                {data.serialNo}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell rowSpan={data.subObjective.length} sx={{ border: '1px solid', padding: '4px' }}><Typography variant="body2" sx={{ fontFamily: 'Lucida Grande, sans-serif' }}>
                                                            {data.Objective}
                                                        </Typography></TableCell>
                                                    </>
                                                )}
                                                {/* <TableCell sx={{ border: '1px solid', padding: '4px' }}> */}
                                                {/* <Typography variant="body2" sx={{ fontFamily: 'Lucida Grande, sans-serif' }}>
                                                    {sub.desc}
                                                </Typography> */}
                                                {/* </TableCell> */}
                                                <TableCell sx={{ border: '1px solid', padding: '4px' }}><Typography variant="body2" sx={{ fontFamily: 'Lucida Grande, sans-serif' }}>
                                                    {sub.matrixTarget}
                                                </Typography></TableCell>
                                                {/* {subIndex === 0 && (
                                                    <TableCell rowSpan={data.subObjective.length} sx={{ border: '1px solid', padding: '4px' }}><Typography variant="body2" sx={{ fontFamily: 'Lucida Grande, sans-serif' }}>
                                                        {data.timePeriod}
                                                    </Typography></TableCell>
                                                )} */}
                                                <TableCell sx={{ border: '1px solid', padding: '4px', fontFamily: 'Lucida Grande, sans-serif' }}>
                                                    <Rating
                                                        name={`rating-${rowIndex}-${subIndex}`}
                                                        onChange={(event, newValue) => handleChange(rowIndex, subIndex, newValue)}
                                                        value={
                                                            ratings.find(r => r.rowIndex === rowIndex && r.subIndex === subIndex)?.rating || 0
                                                        }
                                                        readOnly={Object.keys(appraisaldata).length !== 0 || submitted}
                                                        precision={0.5}
                                                    />

                                                </TableCell>

                                            </TableRow>

                                        ))}
                                    </React.Fragment>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <Dialog open={isDialogOpen}>
                    <DialogTitle>Confirm</DialogTitle>
                    <DialogContent>
                        The ratings submitted are unable to change once submitted. Do you want to continue?
                    </DialogContent>
                    <DialogActions>
                        <Button sx={{ color: 'white' }} onClick={() => { setIsDialogOpen(false) }}>cancel</Button>
                        <Button sx={{ color: 'white' }} onClick={handleClick}>Ok</Button>
                    </DialogActions>
                </Dialog>

                <TextField
                    component="form"
                    required={true}
                    value={comments}
                    disabled={Object.keys(appraisaldata).length !== 0 || submitted}
                    fullWidth type='text' multiline='true' rows='2'
                    placeholder='Write your feedback here...'
                    sx={{ mt: 2 }}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                        sx: {
                            fontSize: '14px',
                            '& .MuiInputBase-input::placeholder': {
                                fontSize: '14px',
                            },
                        },
                    }}
                    onChange={(e) => setComments(e.target.value)}>
                </TextField>
            </CardContent>

            <CardActions sx={{ justifyContent: "flex-end" }} >

                {
                    submitloading ? (
                        <CircularProgress />
                    ) : (
                        Object.keys(appraisaldata).length === 0 && !submitted ? (
                            <Grid>
                                <Button
                                    size='small'
                                    component="form"
                                    onClick={() => { setIsDialogOpen(true) }}
                                    sx={{ color: 'white', marginRight: '10px' }}
                                >
                                    Submit
                                </Button>
                            </Grid>
                        ) : null
                    )
                }

            </CardActions>
            <Snackbar
                open={snackbarOpen}
                onClose={() => setSnackbarOpen(false)}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                autoHideDuration={3000}>
                <Alert onClose={() => setSnackbarOpen(false)} severity={severity} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>

        </Card>
    )
}

export default UserAppraisal;
