
import { Autocomplete, IconButton, TextField } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import GenericForm from "../../../GenericForm";
import { axiosInstance } from "../../../interceptors/AxiosInterceptor";
import {
  isValidKey,
  isValidTitle
} from "../../edvenswa.emportal.common/validation/Validation";
import {
  DIALOG_FIELD_NAME,
  DIALOG_TYPE,
  PROJECT_CATEGORY_FIELD_ID,
  PROJECT_MANAGER_FIELD_ID,
  PROJECT_CATEGORY_MAPPING,
  PROJECT_DEFAULT_IMAGE,
  PROJECT_START_DATE_FIELD_ID,
  PROJECT_TITLE_FIELD_ID,
  PROJECT_KEY_FIELD_ID,
  PROJECT_COMPANY_FIELD_ID,
  PROJECT_STATUS_FIELD_ID,
  PROJECT_END_DATE_FIELD_ID,
} from "../constants/Constants";
import { getProjectFields } from "../fields/GetFields";
import { Add } from "@mui/icons-material";
import GenericDialog from "../../edvenswa.emportal.common/GenericDialog";
import Button from '@mui/material/Button';

const AddProject = (props) => {
  const {
    setIsDrawerOpen,
    setFilteredElements,
    setElements,
    onSuccess,
    onError,
    tenantId,
    projects,
    companies
  } = props;
  const [formData, setFormData] = useState({});
  const [projectStatus, setProjectStatus] = useState([]);
  const [associates, setAssociates] = useState([]);
  const [projectCategories, setProjectCategories] = useState([]);
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [Companies, setCompanies] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const fields = [
    PROJECT_TITLE_FIELD_ID,
    PROJECT_START_DATE_FIELD_ID,
    PROJECT_END_DATE_FIELD_ID,
    PROJECT_KEY_FIELD_ID,
    PROJECT_CATEGORY_FIELD_ID,
    PROJECT_MANAGER_FIELD_ID,
    PROJECT_COMPANY_FIELD_ID,
  ];
  const formFields = getProjectFields(fields);
  const [errors, setErrors] = useState({});


  useEffect(() => {
    if (projectStatus.length === 0) {
      axiosInstance.get("/project/status").then((res) => {
        setProjectStatus(res.data);
      });
    }
    axiosInstance.get("/project/category").then((res) => {
      setProjectCategories([...res.data]);
    });
    axiosInstance.get(`/user/associatesListbyTenant?tenantId=${tenantId}&type=projectResources`).then((res) => {
      setAssociates(res.data.filter((data) => data.roles[0].name === "Project Manager"));
    });
    axiosInstance.get("/tenant/getAll").then((res) => {
      setCompanies([...res?.data]);
    });
  }, [projectStatus, projectCategories.length]);

  const company = companies.filter((data) => data.id === tenantId)
  const handleChange = (field_name, value) => {
    switch (field_name) {
      case PROJECT_TITLE_FIELD_ID: {

        if (!isValidTitle(value) & value !== "") {
          setErrors({
            ...errors,
            [field_name]: { message: "Title must consist of at least 2 characters and first letter should be capital." },
          });
        }
        else if (projects.some((data) => data["title"].includes(value) & value !== "")) {
          setErrors({
            ...errors,
            [field_name]: { message: "Project with this titlename already existed" },
          });
        }
        else {
          setErrors((prevErrors) => {
            delete prevErrors[field_name];
            return { ...prevErrors };
          });
          setFormData({ ...formData, [field_name]: value });
        }
        break;
      }
      case PROJECT_KEY_FIELD_ID: {
        if (!isValidKey(value) & value !== "") {
          setErrors({
            ...errors,
            [field_name]: { message: "Key must consist of minimum 3 characters and All letter should be capital." },
          });
        } else {
          setErrors((prevErrors) => {
            delete prevErrors[field_name];
            return { ...prevErrors };
          });
          setFormData({ ...formData, [field_name]: value });
        }
        break;
      }
      case PROJECT_STATUS_FIELD_ID: {
        setFormData({ ...formData, [field_name]: "ACTIVE" });
        break;
      }
      case PROJECT_CATEGORY_FIELD_ID: {
        setFormData({ ...formData, [field_name]: value });
        break;
      }
      case PROJECT_START_DATE_FIELD_ID:
      case PROJECT_END_DATE_FIELD_ID: {
        const startDate = field_name === PROJECT_START_DATE_FIELD_ID ? value : formData[PROJECT_START_DATE_FIELD_ID];
        const endDate = field_name === PROJECT_END_DATE_FIELD_ID ? value : formData[PROJECT_END_DATE_FIELD_ID];
        const today = new Date();

        if (
          startDate &&
          endDate &&
          (
            new Date(endDate) < new Date(startDate)
          )
        ) {
          setErrors({
            ...errors,
            [PROJECT_START_DATE_FIELD_ID]: {
              message: "Start Date must be before End Date.",
            },
            [PROJECT_END_DATE_FIELD_ID]: {
              message: "End Date must be after Start Date.",
            },
          });
        } else {
          setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            delete updatedErrors[PROJECT_START_DATE_FIELD_ID];
            delete updatedErrors[PROJECT_END_DATE_FIELD_ID];
            return updatedErrors;
          });
        }
        setFormData({ ...formData, [field_name]: value });
        break;
      }
      case PROJECT_COMPANY_FIELD_ID: {
        setFormData({ ...formData, [field_name]: company[0] });
        break;
      }
      case PROJECT_MANAGER_FIELD_ID: {
        setFormData({ ...formData, [field_name]: value });
        break;
      }

      default: {
        console.error("Invalid field name: ", field_name);
      }
    }
  };

  const handleSave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const updatedFormData = {
      ...formData,
      logo: image ? image : PROJECT_DEFAULT_IMAGE,
      tenant: company[0]
    };
    const newProject = {
      logo: image ? image : PROJECT_DEFAULT_IMAGE,
      projectCategory: formData.projectCategory,
      projectManager:formData?.projectManager?.fullname,
      status: "0",
      startDate: formData.startDate,
      targetMargin: null,
      tenant: company[0],
      title:formData.title,
      users: null
    }
    if(formData.endDate){
      newProject.endDate = formData.endDate
    }
    if (Object.keys(errors).length === 0) {
      setLoading(!loading);
      axiosInstance
        .post("/user/createProject", updatedFormData)
        .then((response) => {
          setImage(null);
          setFormData({});
          setLoading(false);
          setFilteredElements((prevElements) => [
            ...prevElements,
            newProject
          ]);
          setElements((prevElements) => [...prevElements, newProject]);
          setIsDrawerOpen(false);
          onSuccess("Project Added Succesfully");
        })
        .catch((error) => {
          onError(error);
          console.error("Error creating project:", error);
          setLoading(false);
        });
    }

  };

  const renderCustomInput = (field, key) => {
    if (field.type !== "radio") {
      return (
        <>

          {
            field.id === PROJECT_CATEGORY_FIELD_ID ?
              <Autocomplete
                sx={{
                  display: "inline-block",
                  "& .MuiAutocomplete-inputRoot": {
                    fontSize: "0.75rem",
                    paddingTop: "4px",
                    paddingBottom: "4px",
                  },
                  "& .MuiAutocomplete-input": {
                    paddingTop: "0",
                  },
                  "& .MuiInputBase-root": {
                    minWidth: "0",
                  },
                  "& .MuiIconButton-root": {
                    padding: "4px",
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "0.75rem",
                  },
                }}
                size="small"
                fullWidth
                onChange={(event, newValue) => {
                  handleChange(field.name, newValue)
                }}
                InputLabelProps={{ shrink: true }}
                options={["Create new", ...projectCategories]}
                // 
                renderOption={(props, option) => (
                  <li {...props} style={{ fontFamily: 'Lucida Grande' }}
                  >
                    {option === "Create new" ? (
                      <Button size="small" style={{ color: 'white' }} onClick={handleOpenDialog}>
                        {option}
                      </Button>
                    ) : (
                      option
                    )}
                  </li>
                )}
                renderInput={(params) => <TextField {...params} label="Project Categories" placeholder="Select Project Category"
                  required
                  InputProps={{
                    ...params.InputProps,
                    style: { fontFamily: 'Lucida Grande' },
                  }}
                  InputLabelProps={{
                    shrink: 'true',
                    style: { fontFamily: 'Lucida Grande' },
                  }}
                />}
                noOptionsText={
                  <Button size="small" style={{ color: 'white', }} onClick={handleOpenDialog}>create new</Button>
                }
              />
              : field.id === PROJECT_MANAGER_FIELD_ID ?
                <Autocomplete
                  sx={{
                    display: "inline-block",
                    "& .MuiAutocomplete-inputRoot": {
                      fontSize: "0.75rem",
                      paddingTop: "4px",
                      paddingBottom: "4px",
                    },
                    "& .MuiAutocomplete-input": {
                      paddingTop: "0",
                    },
                    "& .MuiInputBase-root": {
                      minWidth: "0",
                    },
                    "& .MuiIconButton-root": {
                      padding: "4px",
                    },
                    "& .MuiInputLabel-root": {
                      fontSize: "0.75rem",
                    },
                  }}
                  size="small"
                  fullWidth
                  onChange={(event, newValue) => {
                    if (newValue != null) {
                      console.log(newValue);
                      handleChange(field.name, newValue)
                    }
                  }}
                  InputLabelProps={{ shrink: true }}
                  options={associates}
                  getOptionLabel={(option) => option.fullname}
                  renderOption={(props, option) => (
                    <li {...props} style={{ fontFamily: 'Lucida Grande' }}>
                      {option.fullname}
                    </li>
                  )}
                  renderInput={(params) => <TextField {...params} label="Project Manager" placeholder="Select Project Manager"
                    required
                    InputProps={{
                      ...params.InputProps,
                      style: { fontFamily: 'Lucida Grande' },
                    }}
                    InputLabelProps={{
                      shrink: 'true',
                      style: { fontFamily: 'Lucida Grande' },
                    }}
                  />}
                /> :
                field.select && field.id === PROJECT_COMPANY_FIELD_ID ? (
                  <Autocomplete
                    size="small"
                    sx={{
                      display: "inline-block",
                      "& .MuiAutocomplete-inputRoot": {
                        fontSize: "0.75rem",
                        paddingTop: "4px",
                        paddingBottom: "4px",
                      },
                      "& .MuiAutocomplete-input": {
                        paddingTop: "0",
                      },
                      "& .MuiInputBase-root": {
                        minWidth: "0",
                      },
                      "& .MuiIconButton-root": {
                        padding: "4px",
                      },
                      "& .MuiInputLabel-root": {
                        fontSize: "0.75rem",
                      },
                    }}
                    fullWidth
                    disabled
                    defaultValue={company[0]}
                    onChange={(event, newValue) => {
                      handleChange(field.name, newValue);
                    }}
                    InputLabelProps={{ shrink: true, style: { fontSize: "0.75rem" } }}
                    InputProps={{ style: { font: "small-caption" } }}
                    readOnly
                    options={Companies}
                    // getOptionLabel={(option) => option.name}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField {...params} label="Companies" required InputProps={{
                        ...params.InputProps,
                        style: { fontFamily: 'Lucida Grande' },
                      }}
                        InputLabelProps={{
                          style: { fontFamily: 'Lucida Grande' },
                        }} />
                    )}
                    noOptionsText={
                      <IconButton onClick={handleOpenDialog}>
                        <Add /> Create New
                      </IconButton>
                    }
                  />
                ) :
                  < TextField
                    {...field}
                    fullWidth
                    required={field.required}
                    size="small"
                    key={key}
                    label={field.label}
                    error={errors[field.name] ? true : false}
                    helperText={errors?.[field.name]?.message}
                    InputLabelProps={{ style: { font: 'small-caption', fontFamily: "Lucida Grande" } }}
                    inputProps={{ style: { font: 'small-caption', fontFamily: "Lucida Grande" } }}
                    defaultValue={
                      formData && formData[field.name]
                        ? field.name === PROJECT_START_DATE_FIELD_ID
                          ? moment(formData[field.name], 'YYYY,MM,DD')
                            .format("YYYY-MM-DD")
                          : formData[field.name]
                        : ""
                    }
                    onChange={(event) => handleChange(field.name, event.target.value)}
                  >
                  </TextField >}
        </>
      );
    } else {
      return <React.Fragment />;
    }
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  }


  return (
    <>
      <GenericForm
        formData={formData}
        formFields={formFields}
        errors={errors}
        handleChange={handleChange}
        setIsDrawerOpen={setIsDrawerOpen}
        handleSubmit={handleSave}
        renderCustomInput={renderCustomInput}
        image={image}
        setImage={setImage}
        setLoading={setLoading}
        loading={loading}
        onError={onError}
      />
      <GenericDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        mapping={PROJECT_CATEGORY_MAPPING}
        type={DIALOG_TYPE}
        onSuccess={onSuccess}
        onError={onError}
        fieldName={DIALOG_FIELD_NAME}
        setItems={setProjectCategories}
        items={projectCategories}
      />
    </>
  );
};

export default AddProject;
