import {
  Autocomplete,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useState } from "react";

const AddAssociatesDialog = (props) => {
  const {
    open,
    onClose,
    handleAddAssociates,
    associates,
    formData,
    selectedUsers,
    setSelectedUsers,
    displayedAssociates
  } = props;
  const fixedOptions = formData.users;
  const [users, setUsers] = useState([]);

  const allAssociates = new Set(associates);
  let filteredAssociates = []
  for (const data of allAssociates) {
    if (!displayedAssociates.find(exitedOne => exitedOne.associateId === data.associateId)) {
      filteredAssociates.push(data)
    }
  }
  console.log(filteredAssociates);


  const handleAddAssociate = () => {
    handleAddAssociates(users);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { width: "40%", height: "45%" } }}
    >
      <DialogTitle sx={{ fontFamily: "Lucida Grande" }}>Add Associates</DialogTitle>
      <DialogContent sx={{ maxHeight: 'none' }}>
        <Autocomplete
          style={{ marginTop: '8px' }}
          size="small"
          multiple
          fullWidth
          onChange={(event, newValue) => {
            if (newValue != null) {
              setSelectedUsers(newValue);
              setUsers(newValue);
            }
          }}
          value={selectedUsers}
          // options={associates.filter((user) =>
          //   !(user?.projects !== null && user?.projects.split(",").map((data) => { return data.trim() }).includes(formData.title))

          // )}
          // options={associates.filter((user) =>
          //   !user.projectTitles.includes(formData.title)
          // )}
          options={filteredAssociates}
          filterOptions={(options, { inputValue }) =>
            options.filter(option =>
              option.fullname.toLowerCase().includes(inputValue.toLowerCase())
            )
          }
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                color="primary"
                sx={{
                  fontWeight: 500, opacity: "0.65", fontFamily: 'Lucida Grande', '&.Mui-disabled': {
                    opacity: 1, // Ensure the chip does not fade when disabled
                    backgroundColor: "white", // Keep the same background color
                    color: "#428c98", // Keep the text color white
                  },
                }} // Apply fontFamily here as well if needed
                variant="outlined"
                label={option.fullname}
                {...getTagProps({ index })}
                disabled={fixedOptions.indexOf(option) !== -1}
              />
            ))
          }
          getOptionLabel={(option) => option.fullname}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search Users"
              InputLabelProps={{ shrink: true, style: { fontFamily: "Lucida Grande" } }}
            // You can add additional styles or props here as needed
            />
          )}
          renderOption={(props, option) => (
            <div {...props} style={{ fontFamily: 'Lucida Grande' }}>
              {option.fullname}
            </div>
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button size="small" variant="contained" onClick={onClose}>
          Cancel
        </Button>
        <Button
          size="small"
          variant="contained"
          onClick={handleAddAssociate}
        // disabled={
        //   users.filter((data) => data.role === "Project Manager").length > 1
        // }
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddAssociatesDialog;
