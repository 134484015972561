import { Grid, IconButton, Link, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import GenericTable from "../../../GenericTable";
import { axiosInstance } from "../../../interceptors/AxiosInterceptor";
// import GenerateReport from "./GenerateReport";
import VisibilityIcon from '@mui/icons-material/Visibility';
import 'jspdf-autotable';
import moment from "moment";

const ProjectManagerAppraisal = (props) => {
    const { onError, setLoad, user } = props;
    const navigate = useNavigate();
    const [users, setusers] = useState([]);
    const [filteredusers, setFilteredusers] = useState([]);
    const [username, setusername] = useState("");
    const [userTasks, setUserTasks] = useState([]);
    const [tenantId, setTenantId] = useState(user.tenantId);
    const [project, setProject] = useState("All")
    const [page, setPage] = useState(1);
    const sortBy = "ALL"
    const pageSize = 5;
    const totalPages = 0;
    const [searchTerm, setSearchTerm] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const role = user.roles[0]
    const type = "Appraisals";
    const [startDateError, setStartDateError] = useState("");
    const [endDateError, setEndDateError] = useState("");
    const [startDate, setStartDate] = useState(moment(new Date()).format('DD') > 15 ? moment(new Date()).startOf('month').add(15, 'day').format('YYYY-MM-DD') : moment(new Date()).startOf('month').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment(new Date()).format('DD') > 15 ? moment(new Date()).endOf('month').format('YYYY-MM-DD') : moment(new Date()).startOf('month').add(14, 'days').format('YYYY-MM-DD'));



    // const handleStartDateChange = (e, type) => {
    //   if (type === "icon") {
    //     const newStartDate = moment(startDate).subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
    //     const newEndDate = moment(endDate).subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
    //     setStartDate(newStartDate);
    //     setEndDate(newEndDate);
    //   }
    //   else {
    //     const value = e.target.value;
    //     setStartDate(value);
    //     setEndDateError("");
    //     if (value && endDate && value > endDate) {
    //       setStartDateError("Start date cannot be after end date");
    //     } else {
    //       setStartDateError("");
    //     }
    //   }
    // };

    // const handleEndDateChange = (e, type) => {
    //   if (type === "icon") {
    //     const newStartDate = moment(startDate).add(1, 'month').startOf('month').format('YYYY-MM-DD');
    //     const newEndDate = moment(endDate).add(1, 'month').endOf('month').format('YYYY-MM-DD');
    //     setStartDate(newStartDate);
    //     setEndDate(newEndDate);
    //   }
    //   else {
    //     const value = e.target.value;
    //     setEndDate(value);
    //     setStartDateError("");
    //     if (value && startDate && value < startDate) {
    //       setEndDateError("End date cannot be before start date");
    //     } else {
    //       setEndDateError("");
    //     }
    //   }
    // };


    const CustomNameComponent = (props) => {
        return <Grid sx={{ display: 'flex', alignItems: 'center' }}>
            <Tooltip title="click to view">
                <IconButton onClick={() => {
                    const data = {
                        user: { username: props.data.username }
                    }
                    axiosInstance.put("/user/getRatingsByUser", data).then((res) => {
                        if (res.data.length > 0) {
                            navigate("/home/ratings/UserScreenAppraisal", {
                                state: {
                                    userRatings: res.data, type: type,
                                },
                            });
                            setLoad(false)
                        }
                        else {
                            const error = {
                                "message": `No Ratings Found for the ${props.data.firstname} ${props.data.lastname}`
                            };
                            setLoad(false)
                            onError(error);
                        }
                    }).catch(err => onError(err));
                }}>
                    <VisibilityIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title="click to give ratings">
                <Link sx={{ cursor: 'pointer', }} underline="hover" onClick={() => {
                    // setLoad(true)
                    navigate("/home/ratings/UserAppraisalForm", {
                        state: {
                            fullname: `${props.data.firstname} ${props.data.lastname}`, username: `${props.data.username}`, project: project === "All" ? props.data.projectShow : project,
                            startDate: moment(new Date()).format('DD') > 15 ? moment(new Date()).startOf('month').add(15, 'day').format('YYYY-MM-DD') : moment(new Date()).startOf('month').format('YYYY-MM-DD'),
                            endDate: moment(new Date()).format('DD') > 15 ? moment(new Date()).endOf('month').format('YYYY-MM-DD') : moment(new Date()).startOf('month').add(14, 'days').format('YYYY-MM-DD')

                        },
                    });
                }
                }
                > {props.data.firstname} {props.data.lastname}</Link >
            </Tooltip >
        </Grid >
    };

    const colDefs = [
        {
            field: "associateId",
            headerName: "Associate ID",
            image: "logo",
            type: "text",
            width: 250
        },
        {
            field: "fullname",
            headerName: "Full Name",
            cellRenderer: CustomNameComponent,
            image: "logo",
            type: "text",
            width: 500
        },
    ]
    let projectColumn;
    if (role === "Admin") {
        projectColumn = {
            field: "projectTitles",
            headerName: "Project",
            type: "text",
            width: 500
        };
    } else {
        projectColumn = {
            field: "projectTitles",
            headerName: "Project",
            type: "text",
            width: 500
        };
    }
    colDefs.splice(2, 0, projectColumn);

    const displayAdd = true;

    useEffect(() => {
        setLoading(true)
        if (role === "Project Manager") {
            if (searchTerm.length === 0 && users.length === 0) {
                let arr = []
                if (type === "Appraisals") {
                    user.projects.map((data) =>
                      data.users.map((user) => {
                        let userObj = { ...user };
                        userObj = { ...userObj, projectShow: data.title }
                        if (!arr.find(data => data.associateId === user.associateId)) {
                          arr.push(userObj)
                        }
                      }
                      )
                    )
                }
                else {
                    user.projects.map((data) =>
                        data.users.map((user) => {
                            let userObj = { ...user };
                            userObj = { ...userObj, projectShow: data.title }
                            arr.push(userObj)
                        }
                        )
                    )
                }
                setusers(arr.filter(
                    data => data.associateId !== user.associateId
                ))
                setFilteredusers(arr.filter(
                    data => data.associateId !== user.associateId
                ))
                setLoading(false);
            }
            else {
                const searchedUsers = users.filter((data) => (
                    (data["firstname"].toLowerCase().includes(searchTerm)) ||
                    (data["lastname"].toLowerCase().includes(searchTerm)) ||
                    // (data["fullname"].toLowerCase().includes(searchTerm)) ||
                    (`${data.associateId}`.toLowerCase().includes(searchTerm.toLowerCase()))))
                setFilteredusers(searchedUsers)
                setLoading(false)
            }
        }
        else {
            if (searchTerm.length === 0 && users.length === 0) {
                axiosInstance.get(`/user/associatesLists?sortBy=${sortBy}&tenantId=${tenantId}`).then(res => {
                    setusers(res.data.filter((user) => user.role !== "Admin"));
                    setFilteredusers(res.data.filter((user) => user.role !== "Admin"));
                    setLoading(false)
                })
            } else {
                const searchedUsers = users.filter((data) => (
                    (data["firstname"].toLowerCase().includes(searchTerm)) ||
                    (data["lastname"].toLowerCase().includes(searchTerm)) ||
                    // (data["fullname"].toLowerCase().includes(searchTerm)) ||
                    (`${data.associateId}`.toLowerCase().includes(searchTerm.toLowerCase()))))
                setFilteredusers(searchedUsers)
                setLoading(false)
            }
        }

        if (username.length > 0) {
            const data = {
                username: username
            }
            axiosInstance.post("/task/getAllByUser", data).then((res) => {
                if (res.data.length > 0) {
                    setUserTasks(res.data)
                }
                else {
                    const error = {
                        "message": "No Tasks Found"
                    };
                    props.onError(error);
                }
            });
        }

    }, [page, pageSize, searchTerm, username, tenantId]);


    return (
        <>
            <GenericTable
                type={type}
                setTenantId={setTenantId}
                Companies={props.Companies}
                setUsers={setusers}
                colDefs={colDefs}
                elements={users}
                filteredElements={filteredusers}
                setFilteredElements={setFilteredusers}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                page={page}
                setPage={setPage}
                setProject={setProject}
                totalPages={totalPages}
                snackbarOpen={snackbarOpen}
                setSnackbarOpen={setSnackbarOpen}
                errorMessage={errorMessage}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                // handleStartDateChange={handleStartDateChange}
                // handleEndDateChange={handleEndDateChange}
                loading={loading}
                isDrawerOpen={isDrawerOpen}
                setIsDrawerOpen={setIsDrawerOpen}
                // drawerContent={
                //     <GenerateReport
                //         tenantId={tenantId}
                //         onError={onError}
                //         type={type}
                //         user={user}
                //         setIsDrawerOpen={setIsDrawerOpen}
                //         setFilteredElements={setFilteredusers}
                //         setElements={setusers}
                //     />
                // }
                displayAdd={false}
                displayHeader={displayAdd}
            />

        </>
    );
};

export default ProjectManagerAppraisal;