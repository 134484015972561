import {
    Autocomplete,
    Button,
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { axiosInstance } from "../../../interceptors/AxiosInterceptor";

const AddProjectDialog = ({ open, onClose, handleAddProjects, formData, updatedProjects, setUpdatedProjects, selectedProjects, setSelectedProjects, tenantId }) => {
    const fixedOptions = updatedProjects;
    // const [selectedProjects, setSelectedProjects] = useState([...updatedProjects]);
    const [projectTitles, setProjectTitles] = useState([]);
    const [projects, setProjects] = useState([]);
    const [projectManagerforProjectAlreadyExisted, setProjectManagerforProjectAlreadyExisted] = useState(false)
    const sortBy = "ACTIVE";
    const [loaderopen, setLoaderOpen] = React.useState(false);
    const loading = loaderopen && projectTitles.length === 0;

    console.log(selectedProjects);


    const handleAddProject = () => {
        handleAddProjects(selectedProjects);
        onClose();
    };

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const response = await axiosInstance.get(`/project/projectLists?sortBy=${sortBy}&tenantId=${tenantId}&type=AssignProjects`);
                const projectsData = response.data;
                setProjects(projectsData);

                const activeProjectTitles = projectsData
                    .filter((project) => project.status === '0')
                    .map((project) => project.title);

                const filteredProjectTitles = activeProjectTitles
                    .filter((project) => !selectedProjects?.includes(project))

                setProjectTitles(filteredProjectTitles);
            } catch (error) {
                console.error('Error fetching projects:', error);
            }
        };

        fetchProjects();
    }, [formData, selectedProjects]);

    let arr = []

    console.log(formData);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{ sx: { width: "40%", height: "40%" } }}
        >
            <DialogTitle sx={{ fontFamily: "Lucida Grande" }}>Add Projects</DialogTitle>
            <DialogContent sx={{ maxHeight: 'none' }}>
                <Autocomplete
                    style={{ marginTop: '8px' }}
                    size="small"
                    multiple
                    fullWidth
                    value={selectedProjects}
                    open={loaderopen}
                    onOpen={() => {
                        setLoaderOpen(true);
                    }}
                    onClose={() => {
                        setLoaderOpen(false);
                    }}
                    loading={loading}
                    onChange={(event, newValue) => {
                        if (newValue != null) {
                            const filteredProjects = projects.filter((data) =>
                                newValue.includes(data.title)
                            );
                            filteredProjects.map((filtereddata) => {
                                if (filtereddata.projectManager !== null && filtereddata.projectManager !== formData.fullname) {
                                    arr.push(filtereddata.title)
                                }
                            }
                            )
                            if (newValue.length !== 0 && filteredProjects.some((data) => formData.role === "Project Manager" && data.projectManager !== null && data.projectManager !== formData.fullname)) {
                                setProjectManagerforProjectAlreadyExisted(true)
                            }
                            else {
                                setProjectManagerforProjectAlreadyExisted(false)
                            }
                            setUpdatedProjects(newValue);
                            setSelectedProjects(newValue);
                        }
                    }}
                    InputLabelProps={{ shrink: true }}
                    // options={selectedProjects}
                    options={projectTitles}
                    renderOption={(props, option) => (
                        <div {...props} style={{ fontFamily: 'Lucida Grande' }}>
                            {option}
                        </div>
                    )}
                    renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                            <Chip
                                color="primary"
                                sx={{
                                    fontWeight: 500, opacity: "0.65", fontFamily: "Lucida Grande", '&.Mui-disabled': {
                                        opacity: 1, // Ensure the chip does not fade when disabled
                                        backgroundColor: "white", // Keep the same background color
                                        color: "#428c98", // Keep the text color white
                                    },
                                }}
                                variant="outlined"
                                label={option}
                                {...getTagProps({ index })}
                                disabled={fixedOptions.indexOf(option) !== -1}
                            />
                        ))
                    }
                    // getOptionLabel={(option) => option.title}
                    renderInput={(params) => <TextField {...params} label="Search Projects"
                        InputLabelProps={{ shrink: true, style: { fontFamily: "Lucida Grande" } }}
                        // error={projectManagerforProjectAlreadyExisted}
                        // helperText={projectManagerforProjectAlreadyExisted ?
                        //     `Project Manager is Already Existed For the One of the Selected Project ${arr.map((data) => data)}`
                        //     : ""}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />}
                // noOptionsText={
                //   <IconButton onClick={handleOpenDialog}>
                //     <Add /> Create New
                //   </IconButton>
                // }
                />
            </DialogContent>
            <DialogActions>
                <Button size="small" variant="contained" onClick={onClose}>
                    Cancel
                </Button>
                <Button size="small" variant="contained" onClick={handleAddProject}
                // disabled={projectManagerforProjectAlreadyExisted}
                >
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddProjectDialog;
