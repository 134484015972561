import { InfoOutlined } from "@mui/icons-material";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  IconButton,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import Link from "@mui/material/Link";
import saveAs from "file-saver";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { utils, write } from "xlsx";
import GenericTable from "../../../GenericTable";
import { axiosInstance } from "../../../interceptors/AxiosInterceptor";
import AddProjectDialog from "../../edvenswa.emportal.project/pages/AddProjectDialog";
import {
  ASSOCIATE_DEFAULT_IMAGE,
  ASSOCIATE_PROJECT_ICON,
} from "../constants/Constants";
import Adduser from "./AddUser";

const Users = (props) => {
  const { onError, onSuccess, user } = props;
  const navigate = useNavigate();
  const [users, setusers] = useState([]);
  const [filteredusers, setFilteredusers] = useState([]);
  const [username, setusername] = useState("");
  const [page, setPage] = useState(1);
  const [tenantId, setTenantId] = useState(user.tenantId);
  const pageSize = 10;
  const totalPages = 0;
  const [searchTerm, setSearchTerm] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sortBy, setSortBy] = useState("ACTIVE");
  const type = "Associates";

  const role = user.roles[0];
  const header = [
    {
      id: "associateId",
      label: "Associate Id",
      image: "logo",
      src: ASSOCIATE_DEFAULT_IMAGE,
      type: "text",
    },
    {
      id: "fullname",
      label: "full name",
      type: "text",
    },
    {
      id: "username",
      label: "email",
      type: "text",
    },
    {
      id: "designation",
      label: "job title",
      type: "text",
    },
    {
      id: "dateOfJoining",
      label: "date Of Joining",
      type: "date",
    },
    {
      id: "project",
      label: "project details",
      image: "icon",
      src: ASSOCIATE_PROJECT_ICON,
      type: "text",
    },
    {
      id: "roles",
      label: "role",
      type: "text",
    },
  ];
  const displayAdd = true;


  const CustomNameComponent = (props) => {
    const { logo, firstname, lastname } = props.data;
    return (
      // <Tooltip title="User Overview">
      //   <Link
      //     sx={{ cursor: "pointer", display: "flex", alignItems: "center" }}
      //     underline="hover"
      //     onClick={() => {
      //       navigate("/home/users/details", {
      //         state: { user: props.data, tenantId: tenantId },
      //       });
      //     }}
      //   >
      //     {/* <Avatar src={logo}
      //       alt={`${firstname} ${lastname}`}
      //       slotProps={{
      //         img: {
      //           loading: "lazy",
      //         },
      //       }} /> */}
      //     <Typography
      //       variant="body2"
      //       sx={{ marginLeft: "8px" }}
      //       color="currentcolor"
      //     >
      //       {firstname} {lastname}
      //     </Typography>
      //   </Link>
      // </Tooltip>
      <Tooltip title="User Overview">
        <Link
          mt={2}
          // sx={{ cursor: "pointer", display: "flex", alignItems: "center" }}
          underline="hover"
          onClick={() => {
            navigate("/home/users/details", {
              state: { user: props.data, tenantId: tenantId },
            });
          }}
        >

          <Typography
            mt={1.6}
            variant="body2"
            // sx={{ marginLeft: "8px" }}
            color="currentcolor"
          >
            {firstname} {lastname}
          </Typography>
        </Link>
      </Tooltip>

    );
  };

  const InfoIconCellRenderer = (props) => {
    const [selectedProjects, setSelectedProjects] = useState(
      props?.data?.projectTitles
    );
    const userData = props.data;
    const [anchorEl, setAnchorEl] = useState(null);
    const [projectDetails, setProjectDetails] = useState([]);
    const [updatedProjects, setUpdatedProjects] = useState([
      // ...formData?.projectTitles,
    ]);
    const [filteredProjects, setFilteredProjects] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isAddProjectsDialogOpen, setIsAddProjectsDialogOpen] =
      useState(false);
    const handlePopoverOpen = (event, item, type) => {
      setLoading(true);
      if (type === "PROJECT DETAILS") {
        axiosInstance
          .put("/user/associateProjects", {
            username: item,
          })
          .then((res) => {
            setProjectDetails(res.data);
            setFilteredProjects(res.data);
            const arr = res.data.map((data) => data.title);
            setUpdatedProjects(arr);
          })
          .catch((err) => {
            console.error("Error:", err.response.data);
          })
          .finally(() => {
            setLoading(false);
          });
      }
      setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
      setAnchorEl(null);
    };

    const handleAddProjectsDialogOpen = () => {
      setIsAddProjectsDialogOpen(true);
    };

    const handleAddProjects = (newProjects) => {
      setIsAddProjectsDialogOpen(false);
      let ftp = [];
      newProjects.map((data) => {
        const add = filteredProjects.some((project) => project.title === data);
        if (!add) {
          ftp.push(data);
        }
      });

      if (ftp.length === 0) {
        onError({
          response: {
            data: "Projects are Already existed or you Haven't any project",
          },
        });
      } else {
        axiosInstance
          .post(`user/assignProjectstoUser?id=${userData.associateId}`, {
            projectTitles: ftp,
          })
          .then((response) => {
            setIsAddProjectsDialogOpen(false);
            setAnchorEl(null);
            onSuccess("Projects assigned successfully");
          })
          .catch((err) => {
            onError({ response: { data: "Error adding Projects" } });
            console.error("Error adding projects:", err);
          })
          .finally(() => {
            setIsAddProjectsDialogOpen(false);
          });
      }
    };

    const open = Boolean(anchorEl);
    return (
      <div style={{ textAlign: "center" }}>
        {isAddProjectsDialogOpen && (
          <AddProjectDialog
            updatedProjects={updatedProjects}
            setUpdatedProjects={setUpdatedProjects}
            formData={userData}
            open={isAddProjectsDialogOpen}
            onClose={() => setIsAddProjectsDialogOpen(false)}
            handleAddProjects={handleAddProjects}
            selectedProjects={selectedProjects}
            setSelectedProjects={setSelectedProjects}
          />
        )}
        <Tooltip title="Click for project info">
          <IconButton size="small" disabled={userData?.status === "INACTIVE"}>
            <InfoOutlined
              onClick={(event) =>
                handlePopoverOpen(event, props.data.username, "PROJECT DETAILS")
              }
            />
          </IconButton>
        </Tooltip>
        <Popover
          id={"project-details"}
          open={open}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Card sx={{ width: 400 }}>
            <CardHeader
              title={"Project Details"}
              sx={{
                textAlign: "center",
                color: "white",
                backgroundColor: "#d5ecec",
                padding: "6px",
              }}
            />
            <CardContent>
              <Grid container>
                {role === "Project Manager" ? (
                  <></>
                ) : (
                  <Button
                    sx={{ color: "white" }}
                    size="small"
                    onClick={handleAddProjectsDialogOpen}
                  >
                    Assign Projects
                  </Button>
                )}
                <Table>
                  <TableHead>
                    {["Project Name", "Project Manager"].map((headerCell) => (
                      <TableCell>
                        <Typography variant="h7" style={{ fontWeight: "bold" }}>
                          {headerCell}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <TableRow>
                        <TableCell colSpan={3} align="center">
                          <CircularProgress />
                        </TableCell>
                      </TableRow>
                    ) : projectDetails && projectDetails.length > 0 ? (
                      projectDetails.map((project, index) => (
                        <TableRow key={project.title}>
                          <TableCell>{project.title}</TableCell>
                          <TableCell>{project?.projectManager?.fullname}</TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={2} align="center">
                          <Typography>No Data Found</Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Grid>
            </CardContent>
          </Card>
        </Popover>
      </div>
    );
  };

  const columnDefs = [
    {
      field: "associateId",
      headerName: "Associate Id",
      type: "number",
    },
    {
      field: "associate name",
      type: "text",
      width: 250,
      cellRenderer: CustomNameComponent,
    },
    {
      field: "username",
      headerName: "Email",
      type: "text",
      width: 200,
    },
    {
      field: "designation",
      headerName: "Designation",
      type: "text",
      width: 150,
    },
    {
      field: "role",
      headerName: "Role",
      cellEditorPopup: true,
      filter: true,
      // valueGetter: (params) =>
      //   params.data.roles ? params.data.roles[0]?.name : "",
    },
    {
      field: "project",
      headerName: "Project Details",
      type: "text",
      cellRenderer: InfoIconCellRenderer,
    },
    {
      field: "dateOfJoining",
      headerName: "Date of Joining",
      filter: true,
      valueFormatter: (startDate) =>
        moment(startDate.value, "YYYY,MM,DD").format("DD-MMM-YYYY"),
    },
    role === "Admin" ? {
      field: "tenant",
      headerName: "Company",
      filter: true,
      width: 180,
      // valueGetter: (params) =>
      //   params?.data?.tenant ? params?.data?.tenant?.name : "",
    } :
      {
        field: "tenant",
        headerName: "Company",
        filter: true,
        width: 180,
        valueGetter: (params) =>
          params?.data?.tenant ? params?.data?.tenant?.name : "",
      },
    {
      field: "mobile",
      headerName: "Mobile Number",
      type: "text",
    },
  ];
  const header1 = [
    { id: "associateId", label: "Associate Id", type: "number" },
    { id: "fullname", label: "Associate Name", type: "text" },
    { id: "username", label: "Email", type: "text" },
    { id: "designation", label: "Designation", type: "text" },
    { id: "roles", label: "Role", type: "text" },
    { id: "project", label: "Projects", type: "text" },
    { id: "dateOfJoining", label: "Date of Joining", type: "date" },
    { id: "mobile", label: "Mobile Number", type: "text" },

  ];
  const handleDownloadReport = () => {
    const pdfWidth = 210;
    const pdfHeight = 280;
    const pdf = new jsPDF({
      orientation: "landscape",
      unit: "mm",
      format: [pdfWidth, pdfHeight],
    });
    const logoUrl =
      "https://tse3.mm.bing.net/th?id=OIP.xrnfPsu-nIEVf0HzuyWt3AAAAA&pid=Api&P=0&h=180";
    const centerX = pdf.internal.pageSize.width / 2;
    const imageWidth = 20;
    const imageHeight = 20;
    const imageY = 2;
    pdf.addImage(
      logoUrl,
      centerX - imageWidth / 2,
      imageY,
      imageWidth,
      imageHeight
    );

    const formattedData = users.map((user) => {
      return {
        "Associate Id": user.associateId,
        "Associate Name": user.fullname,
        "Email": user.username,
        "Designation": user.designation,
        "Role": user.role,
        "Projects": user.projectTitles.join(",\n"),
        "Date of Joining": user.dateOfJoining
          ? moment(user.dateOfJoining, "YYYY,MM,DD").format("DD-MMM-yyyy")
          : " ",
        "Mobile Number": user.mobile,
        "tenant": user.tenant.name,
      };
    });

    const tenantName = formattedData.length > 0 ? formattedData[0].tenant : "Unknown Tenant";
    const userData = formattedData.map((val) =>
      header1.map((header) => val[header.label])
    );
    const tableStartY = imageY + imageHeight + 20;

    pdf.autoTable({
      head: [header1.map((h) => h.label)],
      body: userData,
      startY: tableStartY,
      headStyles: {
        fillColor: "#008080",
        textColor: "#ffffff",
        fontStyle: "bold",
      },
      didDrawPage: function (data) {
        if (data.pageNumber === 1) {
          pdf.setFont("Times New Roman, Times, serif");
          pdf.text(
            `Dear Admin, Following is the Associates Details Report of ${tenantName}`,
            12,
            tableStartY - 6
          );
          pdf.setFontSize(13);
        }
      },
      didParseCell: function (data) {
        data.cell.styles.lineWidth = 0.1;
        data.cell.styles.lineColor = [0, 0, 0];
      },
    });
    pdf.save(`All Associates Report.pdf`);
  };

  const handleDownloadReportExcel = () => {
    const fileName = "All_Associates_Report.xlsx";
    const formattedData = users.map((user) => {
      return {
        "Associate Id": user.associateId,
        FullName: user.fullname,
        Email: user.username,
        "Job Title": user.designation,
        Projects: user.projectTitles.join(", "),
        Role: user.role,
        "Date of Joining": user.dateOfJoining
          ? moment(user.dateOfJoining, "YYYY,MM,DD").format("DD-MMM-yyyy")
          : " ",
        "Date of Birth": user.dateOfBirth
          ? moment(user.dateOfBirth, "YYYY,MM,DD").format("DD-MMM-yyyy")
          : " ",
        "Mobile Number": user.mobile,
      };
    });

    const header1 = [
      { label: "Associate Id", key: "Associate Id" },
      { label: "FullName", key: "FullName" },
      { label: "Email", key: "Email" },
      { label: "Designation", key: "Job Title" },
      { label: "Role", key: "Role" },
      { label: "Projects", key: "Projects" },
      { label: "Date of Joining", key: "Date of Joining" },
      { label: "Date of Birth", key: "Date of Birth" },
      { label: "Mobile Number", key: "Mobile Number" },
    ];

    const worksheet = utils.json_to_sheet(formattedData, {
      header: header1.map((h) => h.label),
    });
    const workbook = {
      Sheets: { data: worksheet },
      SheetNames: ["data"],
    };
    const excelBuffer = write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const data = new Blob([excelBuffer], { type: fileType });
    saveAs(data, fileName);
  };

  useEffect(() => {
    setLoading(true);
    if (role === "Project Manager") {
      if (searchTerm.length === 0) {
        // const usersFromProject = user.projects.length !== 0 ? user.projects[0]?.users.filter((data) => data.associateId !== user.associateId) : []
        // setusers(usersFromProject)
        // setFilteredusers(usersFromProject)
        let arr = []
        user.projects.map((data) =>
          data.users.map((user) => {
            let userObj = { ...user };
            userObj = { ...userObj, projectShow: data.title }
            if (!arr.find(data => data.associateId === user.associateId)) {
              arr.push(userObj)
            }
          }
          )
        )
        // const usersFromProject = user.projects.length !== 0 ? user.projects[0]?.users.filter((data) => data.associateId !== user.associateId) : []
        setusers(arr.filter(
          data => data.associateId !== user.associateId
        ))
        setFilteredusers(arr.filter(
          data => data.associateId !== user.associateId
        ))
        setLoading(false);
      } else {
        const searchedUsers = users.filter(
          (data) =>
            `${data.fullname}`.toLowerCase().includes(searchTerm.toLowerCase()) ||
            `${data.firstname}`.toLowerCase().includes(searchTerm.toLowerCase()) ||
            `${data.lastname}`.toLowerCase().includes(searchTerm.toLowerCase()) ||
            `${data.associateId}`.toLowerCase().includes(searchTerm)
        );
        setFilteredusers(searchedUsers);
        setLoading(false);
      }
    } else {

      if (searchTerm.length === 0 && users.length === 0) {
        axiosInstance
          .get(`/user/associatesLists?sortBy=${sortBy}&tenantId=${tenantId}`)
          .then((res) => {
            const users = res.data.filter((user) => user.associateId !== 2000);
            setusers(users);
            setFilteredusers(users);
            setLoading(false);
          });
      } else {
        if (users.length !== 0) {
          const searchedUsers = users.filter(
            (data) =>
              data?.["firstname"].toLowerCase().includes(searchTerm) ||
              data?.["lastname"].toLowerCase().includes(searchTerm) ||
              data?.["fullname"].toLowerCase().includes(searchTerm) ||
              `${data.associateId}`
                .toLowerCase()
                .includes(searchTerm.toLowerCase())
          );
          setFilteredusers(searchedUsers);
          setLoading(false);
        } else {
          setFilteredusers([]);
          setLoading(false);
        }
      }
    }
  }, [page, pageSize, searchTerm, username, sortBy, tenantId]);

  // useEffect(() => {
  //   if (selectedUser.associateId) {
  //     navigate("/home/users/details", {
  //       state: { user: selectedUser },
  //     });
  //   }
  // }, [selectedUser, navigate, filteredusers.length]);



  const toggleData = (item) => {
    setusername(item.username);
  };

  return (
    <>
      <GenericTable
        Companies={props.Companies}
        setCompanies={props.setCompanies}
        sortBy={sortBy}
        setSortBy={setSortBy}
        colDefs={columnDefs}
        type={type}
        setTenantId={setTenantId}
        elements={users}
        setUsers={setusers}
        filteredElements={filteredusers}
        setFilteredElements={setFilteredusers}
        fields={header}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        page={page}
        setPage={setPage}
        totalPages={totalPages}
        toggleData={toggleData}
        snackbarOpen={snackbarOpen}
        setSnackbarOpen={setSnackbarOpen}
        handleDownloadReport={handleDownloadReport}
        handleDownloadReportExcel={handleDownloadReportExcel}
        loading={loading}
        isDrawerOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
        onError={onError}
        onSuccess={onSuccess}
        drawerContent={
          <Adduser
            tenantId={tenantId}
            companies={props.Companies}
            onError={onError}
            onSuccess={onSuccess}
            setIsDrawerOpen={setIsDrawerOpen}
            setFilteredElements={setFilteredusers}
            setElements={setusers}
            users={users}
          />
        }
        displayAdd={displayAdd}
        downloadTitle={"Download Users"}
        displayHeader={displayAdd}
      />
    </>
  );
};

export default Users;
