import { Button, Grid, Link, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import GenericTable from "../../../GenericTable";
import { axiosInstance } from "../../../interceptors/AxiosInterceptor";
import GenerateReport from "./GenerateReport";
import moment from "moment";
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { utils, write } from "xlsx";
import saveAs from "file-saver";

const ReportComponent = (props) => {
  const { onError, setLoad, user } = props;
  const navigate = useNavigate();
  const [users, setusers] = useState([]);
  const [filteredusers, setFilteredusers] = useState([]);
  const [username, setusername] = useState("");
  const [userTasks, setUserTasks] = useState([]);
  const [tenantId, setTenantId] = useState(user.tenantId);
  const [project, setProject] = useState("All")
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState("ACTIVE");
  const pageSize = 5;
  const totalPages = 0;
  const [searchTerm, setSearchTerm] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const role = user.roles[0]
  const type = props.type ? props.type : "Report";
  const [startDateError, setStartDateError] = useState("");
  const [endDateError, setEndDateError] = useState("");
  const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD'));

  // const handleStartDateChange = (e, type) => {
  //   if (type === "icon") {
  //     const newStartDate = moment(startDate).subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
  //     const newEndDate = moment(endDate).subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
  //     setStartDate(newStartDate);
  //     setEndDate(newEndDate);
  //   }
  //   else {
  //     const value = e.target.value;
  //     setStartDate(value);
  //     setEndDateError("");
  //     if (value && endDate && value > endDate) {
  //       setStartDateError("Start date cannot be after end date");
  //     } else {
  //       setStartDateError("");
  //     }
  //   }
  // };

  // const handleEndDateChange = (e, type) => {
  //   if (type === "icon") {
  //     const newStartDate = moment(startDate).add(1, 'month').startOf('month').format('YYYY-MM-DD');
  //     const newEndDate = moment(endDate).add(1, 'month').endOf('month').format('YYYY-MM-DD');
  //     setStartDate(newStartDate);
  //     setEndDate(newEndDate);
  //   }
  //   else {
  //     const value = e.target.value;
  //     setEndDate(value);
  //     setStartDateError("");
  //     if (value && startDate && value < startDate) {
  //       setEndDateError("End date cannot be before start date");
  //     } else {
  //       setEndDateError("");
  //     }
  //   }
  // };

  let totalDates = [];
  for (
    let i = moment(startDate);
    moment(i).isSameOrBefore(endDate);
    i = moment(i).add(1, "day")
  ) {
    if (
      moment(i).format("dddd").toLowerCase() !== "saturday" &&
      moment(i).format("dddd").toLowerCase() !== "sunday"
    ) {
      totalDates.push(i);
    }
  }

  const countLeaves = (filteredElements) => {
    let count = 0;
    filteredElements.forEach(element => {
      if (element.taskCategory === "Leave") {
        const start = new Date(element.startDate[0], element.startDate[1], element.startDate[2]);
        const end = new Date(element.endDate[0], element.endDate[1], element.endDate[2]);
        count += (end - start) / (1000 * 60 * 60 * 24) + 1;
      }
    });
    return count;
  };



  const CustomNameComponent = (props) => {
    return <Link sx={{ cursor: 'pointer', }} underline="hover" onClick={() => {
      setLoad(true)

      if (type === "Appraisals") {
        const data = {
          user: { username: props.data.username }
        }
        axiosInstance.put("/user/getRatingsByUser", data).then((res) => {
          if (res.data.length > 0) {
            navigate("/home/ratings/UserScreenAppraisal", {
              state: { userRatings: res.data, type: type, startDate: startDate, endDate: endDate },
            });
            setLoad(false)
          }
          else {
            const error = {
              "message": `No Ratings Found for the ${props.data.firstname} ${props.data.lastname}`
            };
            setLoad(false)
            onError(error);
          }
        }).catch(err => onError(err));
      }
      else {
        const data = {
          startDate: startDate,
          endDate: endDate,
          associate: props.data.associateId
        }
        axiosInstance.put("/task/getbyuserbystarddateandenddate", data).then((res) => {
          if (res.data.length > 0) {
            navigate("/home/reports/download", {
              state: { userTasks: res.data, fullname: `${props.data.firstname} ${props.data.lastname}`, project: project === "All" ? props.data.projectShow : project, startDate: startDate, endDate: endDate },
            });
            setLoad(false)
          }
          else {
            const error = {
              "message": `No Tasks Found for the ${props.data.firstname} ${props.data.lastname} for the selected Dates`
            };
            setLoad(false)
            onError(error);
          }
        }).catch(err => onError(err));
      }
    }
    }
    >{props.data.firstname} {props.data.lastname}</Link>;
  };
  const CustomRatingComponent = (props) => {

    return <Grid sx={{ display: 'flex', mt: 1, alignItems: 'center' }}>
      <Typography >{props.data.ratings.toFixed(2)}</Typography>
    </Grid>
  };

  const CustomButtonComponent = (props) => {
    return (
      <>
        <Tooltip title="click to Download PDF">
          <Button
            size="small"
            InputLabelProps={{
              style: { font: "small-caption", fontFamily: "Lucida Grande" },
              shrink: true,
            }}
            onClick={() => handleDownloadPDF(props)}
            InputProps={{ style: { font: "small-caption", fontFamily: "Lucida Grande" } }}
            variant="outlined"
            style={{ color: "white", marginRight: "10px" }}
          >
            PDF
          </Button>
        </Tooltip>
        <Tooltip title="click to Download Excel">
          <Button
            size="small"
            InputLabelProps={{
              style: { font: "small-caption", fontFamily: "Lucida Grande" },
              shrink: true,
            }}
            onClick={() => handleDownloadReport(props)}
            InputProps={{ style: { font: "small-caption", fontFamily: "Lucida Grande" } }}
            variant="outlined"
            style={{ color: "white" }}
          >
            Excel
          </Button>
        </Tooltip>
      </>
    );
  }


  const colDefs = [
    {
      field: "associateId",
      headerName: "Associate ID",
      image: "logo",
      type: "text",
      width: 150
    },
    {
      field: "fullname",
      headerName: "Full Name",
      cellRenderer: CustomNameComponent,
      image: "logo",
      type: "text",
      width: 300
    },
  ]
  let projectColumn;
  if (role === "Admin") {
    projectColumn = {
      field: type === "Appraisals" ? "projectTitles" : "projects",
      headerName: "Project",
      type: "text",
      width: 300
    };
  } else {
    projectColumn = {
      field: "projectShow",
      headerName: "Project",
      type: "text",
      width: 300
    };
  }
  colDefs.splice(2, 0, projectColumn);

  colDefs.push(
    {
      field: "username",
      label: "user name",
      type: "text",
      width: 300
    },
    props.type === "Appraisals" ? {
      field: "ratings",
      headerName: "Avg. Rating",
      type: "text",
      width: 300,
      cellRenderer: CustomRatingComponent,
    } : {
      field: "Download",
      headerName: "Download",
      type: "text",
      cellRenderer: CustomButtonComponent,
      width: 300
    }
  );

  const displayAdd = true;

  useEffect(() => {
    setLoading(true)
    if (role === "Project Manager") {
      if (searchTerm.length === 0 && users.length === 0) {
        let arr = []
        user.projects.map((data) =>
          data.users.map((user) => {
            let userObj = { ...user };
            userObj = { ...userObj, projectShow: data.title }
            arr.push(userObj)
          }
          )
        )
        setusers(arr.filter(
          data => data.associateId !== user.associateId
        ))
        setFilteredusers(arr.filter(
          data => data.associateId !== user.associateId
        ))
        setLoading(false);
      }
      else {
        const searchedUsers = users.filter((data) => (
          (data["firstname"].toLowerCase().includes(searchTerm)) ||
          (data["lastname"].toLowerCase().includes(searchTerm)) ||
          (data["fullname"].toLowerCase().includes(searchTerm)) ||
          (`${data.associateId}`.toLowerCase().includes(searchTerm.toLowerCase()))))
        setFilteredusers(searchedUsers)
        setLoading(false)
      }
    }
    else {
      if (type === "Appraisals" && searchTerm.length === 0) {
        axiosInstance.post(`/user/getAllassociatesforRatings?sortBy=${sortBy}&tenantId=${tenantId}`,
          { startDate: props.startDate, endDate: props.endDate }).then(res => {
            setusers(res.data.filter((user) => user.role !== "Admin"));
            setFilteredusers(res.data.filter((user) => user.role !== "Admin"));
            setLoading(false);
          })
      }
      else if (searchTerm.length === 0) {
        axiosInstance.get(`/user/associatesLists?sortBy=${sortBy}&tenantId=${tenantId}`).then(res => {
          setusers(res.data.filter((user) => user.role !== "Admin"));
          setFilteredusers(res.data.filter((user) => user.role !== "Admin"));
          setLoading(false);
        })
      }
      else {
        const searchedUsers = users.filter((data) => (
          (data["firstname"].toLowerCase().includes(searchTerm)) ||
          (data["lastname"].toLowerCase().includes(searchTerm)) ||
          // (data["fullname"].toLowerCase().includes(searchTerm)) ||
          (`${data.associateId}`.toLowerCase().includes(searchTerm.toLowerCase()))))
        setFilteredusers(searchedUsers)
        setLoading(false)
      }
    }

    if (username.length > 0) {
      const data = {
        username: username
      }
      axiosInstance.post("/task/getAllByUser", data).then((res) => {
        if (res.data.length > 0) {
          setUserTasks(res.data)
        }
        else {
          const error = {
            "message": "No Tasks Found"
          };
          props.onError(error);
        }
      });
    }

  }, [page, pageSize, searchTerm, username, tenantId, sortBy, props.startDate, props.endDate]);


  const handleDownloadPDF = (props) => {
    let filteredElements = [];
    let leaveCount = 0;

    const data = {
      startDate: startDate,
      endDate: endDate,
      associate: props.data.associateId
    }
    axiosInstance.put("/task/getbyuserbystarddateandenddate", data).then((res) => {
      if (res.data.length > 0) {
        if (role === "Admin") {
          filteredElements = res.data;
          leaveCount = countLeaves(filteredElements)
          goToPDF(filteredElements, props.data.fullname, leaveCount)
        }
        else {
          if (project === "All") {
            filteredElements = res.data.filter((data) => data.project === props.data.projectShow)
            leaveCount = countLeaves(filteredElements)
            goToPDF(filteredElements, props.data.fullname, leaveCount)
          }
          else {
            filteredElements = res.data.filter((data) => data.project === project)
            leaveCount = countLeaves(filteredElements)
            goToPDF(filteredElements, props.data.fullname, leaveCount)
          }
        }
      } else {
        const error = {
          "message": `No Tasks Found for the ${props.data.firstname} ${props.data.lastname} for the selected Dates`
        };
        setLoad(false)
        onError(error);
      }
    }).catch(err => onError(err));
  }

  const goToPDF = (filteredElements, fullname, leaveCount) => {
    let estimatedHourssum = 0;
    let workedHourssum = 0;
    let percentageUsedsum = 0;

    filteredElements.map((task) => {
      estimatedHourssum += task.estimatedHours
      workedHourssum += task.workedHours
      percentageUsedsum += task.percentageUsed
    }
    )
    workedHourssum -= leaveCount * 8 * 3600;

    if (filteredElements.length > 0) {
      const pdfWidth = 210;
      const pdfHeight = 250;
      const pdf = new jsPDF({
        orientation: "landscape",
        unit: "mm",
        format: [pdfWidth, pdfHeight],
      });
      const logoUrl = 'https://tse3.mm.bing.net/th?id=OIP.xrnfPsu-nIEVf0HzuyWt3AAAAA&pid=Api&P=0&h=180';
      const centerX = pdf.internal.pageSize.width / 2;
      const imageWidth = 20;
      const imageHeight = 20;
      const imageY = 2;
      pdf.addImage(logoUrl, centerX - imageWidth / 2, imageY, imageWidth, imageHeight);

      const formattedData = filteredElements.reduce((acc, task) => {
        const taskData = JSON.parse(task?.task)?.workedDayHours !== null && Object.keys(JSON.parse(task?.task).workedDayHours).map((date) => {
          const seconds = JSON.parse(task.task).workedDayHours[date];
          return {
            "Project": task?.project ? task?.project : "",
            "Task Name": task.taskName ? task.taskName : "",
            "Task Start Date": task.startDate ? moment(task.startDate, 'YYYY,MM,DD').format('DD-MMM-yyyy') : " ",
            "Task End Date": task.endDate ? moment(task.endDate, 'YYYY,MM,DD').format('DD-MMM-yyyy') : " ",
            "Task Category": task.category ? task.category : "",
            "Date Logged": moment(date, "DD-MM-YYYY").format("DD-MMM-YYYY"),
            "Task Hours Logged": moment().startOf('day').seconds(seconds).format('HH:mm'),
          };
        });

        return acc.concat(taskData);
      }, []);

      const cleanedFormattedData = formattedData.filter(row => Object.values(row).some(val => val));

      const sortedData = cleanedFormattedData.sort((a, b) => {
        const dateA = moment(a["Date Logged"], 'DD-MMM-YYYY');
        const dateB = moment(b["Date Logged"], 'DD-MMM-YYYY');
        return dateA - dateB;
      });

      const header = [
        "Project",
        "Task Name",
        "Task Start Date",
        "Task End Date",
        "Task Category",
        "Date Logged",
        "Task Hours Logged",
      ];

      const userData = sortedData.filter(val =>
        moment(val["Date Logged"], 'DD-MMM-YYYY').isSameOrAfter(moment(startDate)) && moment(val["Date Logged"], 'DD-MMM-YYYY').isSameOrBefore(moment(endDate)), startDate, endDate
      ).map((val) => header.map((header) => val[header]))

      // Calculate total logged hours
      let totalLoggedSeconds = sortedData.reduce((acc, val) => {
        if (
          moment(val["Date Logged"], 'DD-MMM-YYYY').isSameOrAfter(moment(startDate)) &&
          moment(val["Date Logged"], 'DD-MMM-YYYY').isSameOrBefore(moment(endDate))
        ) {
          const hoursLogged = moment.duration(val["Task Hours Logged"]).asSeconds();
          return acc + hoursLogged;
        }
        return acc;
      }, 0);

      // Calculate the total leave hours in seconds
      const leaveHoursLogged = sortedData
        .filter(val => val["Task Category"] === "Leave")
        .map(val => moment.duration(val["Task Hours Logged"]).asSeconds());

      const totalLeaveSeconds = leaveHoursLogged.reduce((acc, seconds) => acc + seconds, 0);
      const totalLeaveHours = `${Math.floor(totalLeaveSeconds / 3600)}:${Math.floor((totalLeaveSeconds % 3600) / 60).toString().padStart(2, '0')}`;
      console.log(totalLeaveHours);

      //remove totalLeaveSeconds from totalLoggedSeconds
      totalLoggedSeconds = Math.max(0, totalLoggedSeconds - totalLeaveSeconds);

      //converting toatlLoggedSeconds into Hours
      const totalLoggedHours = `${Math.floor(totalLoggedSeconds / 3600)}:${Math.floor((totalLoggedSeconds % 3600) / 60).toString().padStart(2, '0')}`;
      console.log(totalLoggedHours);

      const tableStartY = imageY + imageHeight + 20; //44

      // Average Utilization
      const totalWorkingHoursInSeconds = totalDates.length * 8 * 3600;
      const utilizationPercentage = totalLoggedSeconds / totalWorkingHoursInSeconds * 100;
      const formattedPercentage = `${utilizationPercentage.toFixed(2)} %`;

      pdf.autoTable({
        head: [header],
        body: userData,
        margin: { left: 8, right: 8, bottom: 10 },
        startY: startDate && endDate ? 58 : 58,
        rowPageBreak: 'avoid',
        headStyles: { fillColor: "#008080", textColor: "#ffffff", fontStyle: "bold" },

        didDrawPage: function (data) {

          if (data.pageNumber === 1) {
            pdf.setFont("Times New Roman, Times, serif");
            pdf.text(`Dear Admin, Following is the report of ${fullname}.`, 12, tableStartY - 6);
            pdf.setFontSize(13);
            pdf.text(`For the time period of ${moment(startDate).format('DD-MMM-yyyy')} to ${moment(endDate).format('DD-MMM-yyyy')} `, 12, 46);
            pdf.text((`Total Working Hours- ${Math.floor(totalDates.length * 8)}:00,  Total Task Hours Logged- ${totalLoggedHours}, Total Leave Hours- ${totalLeaveHours},  Avg. Utilization- ${formattedPercentage}`), 12, startDate && endDate ? 54 : 46);
          }
        },
        didParseCell: function (data) {
          data.cell.styles.lineWidth = 0.1;
          data.cell.styles.lineColor = [0, 0, 0];
        },
      });

      pdf.save(`${fullname}.pdf`);
    }
  }

  const handleDownloadReport = (props) => {
    let filteredElements = [];

    const data = {
      startDate: startDate,
      endDate: endDate,
      associate: props.data.associateId
    }
    axiosInstance.put("/task/getbyuserbystarddateandenddate", data).then((res) => {
      if (res.data.length > 0) {
        if (role === "Admin") {
          filteredElements = res.data;
          goToExcel(filteredElements, props.data.fullname)
        }
        else {
          if (project === "All") {
            filteredElements = res.data.filter((data) => data.project === props.data.projectShow)
            goToExcel(filteredElements, props.data.fullname)
          }
          else {
            filteredElements = res.data.filter((data) => data.project === project)
            goToExcel(filteredElements, props.data.fullname)
          }
        }
      } else {
        const error = {
          "message": `No Tasks Found for the ${props.data.firstname} ${props.data.lastname} for the selected Dates`
        };
        setLoad(false)
        onError(error);
      }
    }).catch(err => onError(err));
  }

  const goToExcel = (filteredElements, fullname) => {

    if (filteredElements.length > 0) {
      const fileName = `${fullname} Report.xlsx`;

      const sortedData = filteredElements.sort((a, b) => {
        const dateA = new Date(a.startDate);
        const dateB = new Date(b.startDate);
        return dateA - dateB;
      });

      const formattedData = sortedData.reduce((acc, task) => {
        const taskData = JSON.parse(task?.task)?.workedDayHours !== null && Object.keys(JSON.parse(task?.task).workedDayHours).map((date) => {
          const seconds = JSON.parse(task.task).workedDayHours[date];
          return {
            "Project": task.project ? task.project : "",
            "Task Name": task.taskName ? task.taskName : "",
            "Start Date": task.startDate ? moment(task.startDate, 'YYYY,MM,DD').format('DD-MMM-yyyy') : " ",
            "End Date": task.endDate ? moment(task.endDate, 'YYYY,MM,DD').format('DD-MMM-yyyy') : " ",
            "Task Category": task.category ? task.category : "",
            "Date Logged": moment(date, "DD-MM-YYYY").format("DD-MMM-YYYY"),
            "Task Hours Logged": moment().startOf('day').seconds(seconds).format('HH:mm'),
          };
        });
        return acc.concat(taskData);
      }, []);

      const cleanedFormattedData = formattedData.filter(row => Object.values(row).some(val => val));

      // Sort formattedData by Associate and Date Logged
      const sortedFormattedData = cleanedFormattedData.sort((a, b) => {
        const dateA = moment(a["Date Logged"], 'DD-MMM-yyyy').toDate();
        const dateB = moment(b["Date Logged"], 'DD-MMM-yyyy').toDate();
        return dateA - dateB;
      });

      const header = [
        "Project",
        "Task Name",
        "Start Date",
        "End Date",
        "Task Category",
        "Date Logged",
        "Task Hours Logged",
      ];

      const userData = sortedFormattedData.filter(val =>
        moment(val["Date Logged"], 'DD-MMM-YYYY').isSameOrAfter(moment(startDate)) && moment(val["Date Logged"], 'DD-MMM-YYYY').isSameOrBefore(moment(endDate)), startDate, endDate
      );

      const worksheet = utils.json_to_sheet(userData, { header });
      const workbook = {
        Sheets: { data: worksheet },
        SheetNames: ["data"],
      };
      const excelBuffer = write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const data = new Blob([excelBuffer], { type: fileType });
      saveAs(data, fileName);
    }
  };

  return (
    <>
      <GenericTable
        type={type}
        setTenantId={setTenantId}
        Companies={props.Companies}
        setUsers={setusers}
        colDefs={colDefs}
        elements={users}
        filteredElements={filteredusers}
        setFilteredElements={setFilteredusers}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        setStartDateError={setStartDateError}
        setEndDateError={setEndDateError}
        page={page}
        sortBy={type === "Appraisals" ? props.sortBy : sortBy}
        setSortBy={type === "Appraisals" ? props.setSortBy : setSortBy}
        setPage={setPage}
        setProject={setProject}
        totalPages={totalPages}
        snackbarOpen={snackbarOpen}
        setSnackbarOpen={setSnackbarOpen}
        errorMessage={errorMessage}
        startDate={type === "Appraisals" ? props.startDate : startDate}
        setStartDate={type === "Appraisals" ? props.setStartDate : setStartDate}
        endDate={type === "Appraisals" ? props.endDate : endDate}
        setEndDate={type === "Appraisals" ? props.setEndDate : setEndDate}

        handleDownloadReport={type === "Appraisals" ? props.handleDownloadReport : null}
        handleDownloadReportExcel={type === "Appraisals" ? props.handleDownloadReportExcel : null}
        // handleStartDateChange={handleStartDateChange}
        // handleEndDateChange={handleEndDateChange}
        loading={loading}
        isDrawerOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
        drawerContent={
          <GenerateReport
            tenantId={tenantId}
            onError={onError}
            type={type}
            user={user}
            setIsDrawerOpen={setIsDrawerOpen}
            setFilteredElements={setFilteredusers}
            setElements={setusers}
          />
        }
        displayAdd={props.type === "Appraisals" ? false : displayAdd}
        displayHeader={displayAdd}
      />

    </>
  );
};

export default ReportComponent;